const hasAccessToLocalStorage = ( (): boolean => {
	try {
		window.localStorage.getItem( 'mrh:test:localStorage:access' );
	} catch {
		return false;
	}

	return true;
} )();

function storageKey( group: string, key: string ):string {
	return `mrh:do-once-per:${group}:${key}`;
}


export function doOncePerHour( key: string, doCallback: () => void = () => {}, skipCallback: () => void = () => {} ): void {
	const hour = 60 * 60 * 1000;

	oncePerWithExtendingDuration( 'hour:', hour, key, doCallback, skipCallback );

	return;
}


function oncePerWithExtendingDuration( group: string, duration: number, key: string, doCallback: () => void = () => {}, skipCallback: () => void = () => {} ): void {
	// No access to localStorage, bail!
	if ( !hasAccessToLocalStorage ) {
		skipCallback();

		return;
	}

	let visitedDate :Date|null = null;

	const visitedDateStr = localStorage.getItem( storageKey( group, key ) );
	if ( visitedDateStr ) {
		visitedDate = dateFromJSONString( visitedDateStr );
	}

	// If it is not after xTime, we do not do the thing.
	let extending = false;
	if ( visitedDate && ( ( new Date().valueOf() ) - visitedDate.valueOf() ) < duration ) {
		extending = true;
		skipCallback();
	}

	// Set visitedDate to now
	// To users this feels as if the action is not run again as long as they stay on the site.
	try {
		localStorage.setItem( storageKey( group, key ), JSON.stringify( new Date() ) );
	} catch ( e ) {
		// We already checked for storage access and did not expect errors here.
		console.warn( e );
	}

	// Do the thing!
	if ( !extending ) {
		doCallback();
	}
}

// Having a Date or null is goed enough here
function dateFromJSONString( json: string ): Date|null {
	try {
		const parsed = JSON.parse( json );

		return new Date( parsed );
	} catch ( err ) {
		console.warn( err );

		return null;
	}
}
